import "../styles/styles.scss";
import { Checkbox, Col, ColorPicker, Divider, Switch } from "antd";
import { ImageContainer } from "./ImageContainer";
import { useState } from "react";
import { BuildsContainer } from "./BuildsContainer";
import { Color } from "antd/es/color-picker";
import ExportImport from "./ExportImport";

export const View = () => {
  const [checkedValue, setCheckedValue] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [bgColor, setBgColor] = useState<any>({ color: "", hex: "" });
  const [hideUI, setHideUI] = useState<boolean>(false);
  const [importedBuilds, setImportedBuilds] = useState(null);
  const [currentBuild, setCurrentBuild] = useState<any>();

  const onChange = (checkedValues: any) => {
    setCheckedValue(checkedValues);
  };

  const colourPickerChange = (value: Color, hex: string) => {
    setBgColor({ color: value, hex: hex });
  };

  const changeHideUI = (checked: boolean) => {
    setHideUI(checked);
  };

  const styles = { fontFamily: "GT American" };

  return (
    <div className="row">
      <div
        className="col-12 px-5 py-3 d-flex"
        style={{ backgroundColor: "white", fontWeight: "1px" }}
      >
        <div className="col-9">
          <Checkbox.Group
            style={{
              width: "100%",
            }}
            onChange={onChange}
          >
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="axe" style={styles}>
                Axes
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="dagger" style={styles}>
                Daggers
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="hammer" style={styles}>
                Hammers
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="mace" style={styles}>
                Maces
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="quarterstaff" style={styles}>
                Quarterstaffs
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="spear" style={styles}>
                Spears
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="sword" style={styles}>
                Swords
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="wargloves" style={styles}>
                Wargloves
              </Checkbox>
            </Col>

            <Divider />

            <Col span={1} className="mt-2 me-5">
              <Checkbox value="arcane" style={styles}>
                Arcane
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="cursed" style={styles}>
                Cursed
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="fire" style={styles}>
                Fire
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="frost" style={styles}>
                Frost
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="holy" style={styles}>
                Holy
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="nature" style={styles}>
                Nature
              </Checkbox>
            </Col>

            <Divider />

            <Col span={1} className="mt-2 me-5">
              <Checkbox value="bow" style={styles}>
                Bows
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="crossbow" style={styles}>
                Crossbows
              </Checkbox>
            </Col>

            <Divider />

            <Col span={1} className="mt-2 me-5">
              <Checkbox value="cowls" style={styles}>
                Cowls
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="robes" style={styles}>
                Robes
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="sandals" style={styles}>
                Sandals
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="hoods" style={styles}>
                Hoods
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="jackets" style={styles}>
                Jackets
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="shoes" style={styles}>
                Shoes
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="helmets" style={styles}>
                Helmets
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="armours" style={styles}>
                Armours
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="boots" style={styles}>
                Boots
              </Checkbox>
            </Col>

            <Divider />

            <Col span={1} className="mt-2 me-5">
              <Checkbox value="accessories" style={styles}>
                Accessories
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="offhand" style={styles}>
                Offhands
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="potions" style={styles}>
                Potions
              </Checkbox>
            </Col>
            <Col span={1} className="mt-2 me-5">
              <Checkbox value="food" style={styles}>
                Foods
              </Checkbox>
            </Col>
            <Divider />
          </Checkbox.Group>
        </div>
        <div className="col-9 d-flex align-items-end flex-column mt-2 px-3 py-3">
          <div className="col-10">
            <ExportImport
              savedBuilds={currentBuild}
              setImportedBuilds={setImportedBuilds}
            />
          </div>
          <div className="col-10 mt-3">
            <ColorPicker onChange={colourPickerChange} className="me-3" />
            <span className="gt-american">Select background colour</span>
          </div>
          <div className="col-10 mt-3">
            <Switch onChange={changeHideUI} className="me-3" />
            <span className="mt-1 gt-american ">Hide the UI</span>
          </div>
        </div>
      </div>

      <div className="" style={{ backgroundColor: bgColor.hex }}>
        <BuildsContainer
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          hideUI={hideUI}
          setCurrentBuild={setCurrentBuild}
          importedBuilds={importedBuilds}
        ></BuildsContainer>
      </div>
      <ImageContainer
        itemTypes={checkedValue}
        setSelectedItem={setSelectedItem}
      ></ImageContainer>
    </div>
  );
};
