import React from "react";

export const ImageComponent = ({ item, setSelectedItem }: any) => {
  const handleClick = (item: any) => {
    if (item) {
      setSelectedItem(item);
    }
  };

  if (item) {
    const imagePath = require(`../images${item.path}`);

    return (
      <img
        onClick={() => handleClick(item)}
        className="pointer"
        src={imagePath}
        alt={item.type}
        style={{ width: 100 }}
      />
    );
  } else {
    return <></>;
  }
};

export const BuildImage = ({ item, setBuild, setSelectedItem }: any) => {
  const handleRemove = () => {
    setSelectedItem("");
    setBuild((prevBuild: any) => ({
      ...prevBuild,
      [item.type]: "",
    }));
  };

  if (item) {
    const imagePath = require(`../images${item.path}`);

    return (
      <img
        onClick={handleRemove}
        className="pointer"
        src={imagePath}
        alt={item.type}
        style={{ width: 100 }}
      />
    );
  } else {
    return <></>;
  }
};
