import { View } from "./components/View";
import "./styles/styles.scss";

export const App = () => {
  return (
    <>
      <div className="container-fluid">
        <View />
      </div>
    </>
  );
};

export default App;
