import React, { useEffect, useState } from "react";
import { Button } from "antd";
import {
  PlusCircleOutlined,
  RightCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

export const BuildsContainer = ({
  selectedItem,
  setSelectedItem,
  hideUI,
  setCurrentBuild,
  importedBuilds,
}: any) => {
  const [buildsContainer, setBuildsContainer] = useState<any>([]);
  const [selectedBuild, setSelectedBuild] = useState<number>(0);

  useEffect(() => {
    // Update the buildsContainer state only when importedBuilds changes
    if (importedBuilds) {
      setBuildsContainer(importedBuilds);
    }
  }, [importedBuilds]);

  useEffect(() => {
    if (selectedItem) {
      const itemType = selectedItem.type;

      setBuildsContainer((prevBuilds: any) =>
        prevBuilds.map((prevBuild: any, index: number) => {
          const isItemExists =
            prevBuild.items[itemType] &&
            prevBuild.items[itemType].id === selectedItem.id;

          return {
            ...prevBuild,
            items:
              index === selectedBuild
                ? {
                    ...prevBuild.items,
                    [itemType]: isItemExists ? null : selectedItem,
                  }
                : prevBuild.items,
          };
        })
      );

      setSelectedItem(null);
    }
  }, [selectedItem, selectedBuild, setSelectedItem]);

  useEffect(() => {
    setCurrentBuild(buildsContainer);
  }, [buildsContainer, setCurrentBuild]);

  const addBuild = () => {
    setBuildsContainer((prevBuilds: any) => [
      ...prevBuilds,
      { id: prevBuilds.length + 1, items: {} },
    ]);
  };

  const selectBuild = (index: number) => {
    setSelectedBuild(index);
  };

  const removeItem = (index: number, itemType: string) => {
    setBuildsContainer((prevBuilds: any) =>
      prevBuilds.map((prevBuild: any, i: number) =>
        i === index
          ? { ...prevBuild, items: { ...prevBuild.items, [itemType]: null } }
          : prevBuild
      )
    );
  };

  const removeBuild = (index: number) => {
    setBuildsContainer((prevBuilds: any) =>
      prevBuilds.filter(
        (build: any, buildIndex: number) => buildIndex !== index
      )
    );
  };

  return (
    <div className="col-12 px-5 py-3 mb-3">
      <div className="d-flex justify-content-center row">
        <div className="col-7">
          {buildsContainer.map((build: any, index: number) => {
            const isSelected = selectedBuild === index;
            const plus = `py-4 me-4 opacity-25 ${
              isSelected ? "opacity-75" : ""
            }`;
            const minus = `py-4 ms-4 opacity-25 ${
              isSelected ? "opacity-75" : ""
            }`;
            return (
              <div key={build.id} className="d-flex justify-content-center">
                {hideUI ? (
                  <></>
                ) : (
                  <RightCircleOutlined
                    onClick={() => selectBuild(index)}
                    className={plus}
                    style={{ fontSize: "2.4rem", color: "white" }}
                  />
                )}

                <div>
                  <Build
                    build={build.items}
                    setBuild={(items: any) =>
                      setBuildsContainer((prevBuilds: any) =>
                        prevBuilds.map((prevBuild: any, i: number) =>
                          i === index ? { ...prevBuild, items } : prevBuild
                        )
                      )
                    }
                    setSelectedItem={setSelectedItem}
                    removeItem={removeItem}
                    buildIndex={index}
                  />
                </div>
                {hideUI ? (
                  <></>
                ) : (
                  <MinusCircleOutlined
                    onClick={() => removeBuild(index)}
                    className={minus}
                    style={{ fontSize: "2.4rem", color: "white" }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-4 d-flex justify-content-center">
        {hideUI ? (
          <></>
        ) : (
          <Button
            onClick={addBuild}
            className=""
            style={{
              width: "200px",
              height: "50px",
              opacity: "0.75",
            }}
          >
            <PlusCircleOutlined
              className="pb-1 pt-1"
              style={{ fontSize: "1.5rem" }}
            />
          </Button>
        )}
      </div>
    </div>
  );
};

const Build = ({
  build,
  setBuild,
  setSelectedItem,
  removeItem,
  buildIndex,
}: any) => {
  return (
    <>
      <BuildImage
        item={build.weapon}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="weapon"
        buildIndex={buildIndex}
      />
      <BuildImage
        item={build.offhand}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="offhand"
        buildIndex={buildIndex}
      />
      <BuildImage
        item={build.helmet}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="helmet"
        buildIndex={buildIndex}
      />
      <BuildImage
        item={build.armour}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="armour"
        buildIndex={buildIndex}
      />
      <BuildImage
        item={build.shoes}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="shoes"
        buildIndex={buildIndex}
      />
      <BuildImage
        item={build.accessories}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="accessories"
        buildIndex={buildIndex}
      />
      <BuildImage
        item={build.potion}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="potion"
        buildIndex={buildIndex}
      />
      <BuildImage
        item={build.food}
        setBuild={setBuild}
        setSelectedItem={setSelectedItem}
        removeItem={removeItem}
        itemType="food"
        buildIndex={buildIndex}
      />
    </>
  );
};

const BuildImage = ({
  item,
  setSelectedItem,
  removeItem,
  itemType,
  buildIndex,
}: any) => {
  const handleRemove = () => {
    setSelectedItem("");
    removeItem(buildIndex, itemType);
  };

  if (item) {
    const imagePath = require(`../images${item.path}`);

    return (
      <img
        onClick={handleRemove}
        className="pointer"
        src={imagePath}
        alt={item.type}
        style={{ width: 100 }}
      />
    );
  } else {
    return <></>;
  }
};
