import React from "react";
import { saveAs } from "file-saver";
import { Button, Upload } from "antd";

const ExportImport: any = ({ savedBuilds, setImportedBuilds }: any) => {
  const customRequest = () => {
    // You can implement your own custom request logic if needed
    // For the sake of this example, we'll use an empty function
  };

  const handleExport = () => {
    const jsonContent = JSON.stringify(savedBuilds, null, 2); // Convert to formatted JSON string
    const blob = new Blob([jsonContent], { type: "application/json" });
    saveAs(blob, "zbz-builds.json");
  };

  const handleFileChange = async (info: any) => {
    const file = info.file.originFileObj;

    try {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const importedJSON = JSON.parse(reader.result as string);

          // Update the state with the imported data
          setImportedBuilds(importedJSON);
        } catch (error: any) {
          console.error("Error parsing JSON: " + error.message);
        }
      };

      if (file) {
        reader.readAsText(file);
      }
    } catch (error: any) {
      console.error("Error reading file: " + error.message);
    }
  };

  const uploadButton = (
    <Button>
      <span className="gt-american">Import build</span>
    </Button>
  );

  return (
    <div className="row">
      <div>
        <Button onClick={handleExport}>
          <span className="gt-american">Export current build</span>
        </Button>
      </div>
      <div className="mt-3">
        <Upload
          customRequest={customRequest}
          showUploadList={false}
          accept=".json"
          onChange={handleFileChange}
        >
          {uploadButton}
        </Upload>
      </div>
    </div>
  );
};

export default ExportImport;
