import React, { useEffect, useState } from "react";
import { ImageComponent } from "./ImageComponent";
import Items from "../util/Items.json";

export const ImageContainer = ({ itemTypes, setSelectedItem }: any) => {
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const handleWhichItemsToShow = (itemsToShowArray: string[]) => {
    const allItems: any = Items;
    const filteredData: any[] = [];

    itemsToShowArray.forEach((type) => {
      if (allItems[type]) {
        allItems[type].forEach((item: any) => {
          filteredData.push(item);
        });
      }
    });

    return filteredData;
  };

  const updateFilteredItems = (itemsToShowArray: string[]) => {
    const filteredData = handleWhichItemsToShow(itemsToShowArray);
    setFilteredItems(filteredData);
  };

  useEffect(() => {
    if (itemTypes) updateFilteredItems(itemTypes);
    //eslint-disable-next-line
  }, [itemTypes]);

  return (
    <div className="col-12 px-5 py-3 mb-3" style={{}}>
      {filteredItems.map((item: any, index: number) => {
        return (
          <ImageComponent
            item={item}
            key={index}
            setSelectedItem={setSelectedItem}
          ></ImageComponent>
        );
      })}
    </div>
  );
};
